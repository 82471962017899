import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

const TotalOrdersChart = (props) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
    },
    scales: {
      x: {
        ticks: {
          fontSize: 10,
        },
      },
    },
  };

  const labels = props?.analyticsData?.totalSalesVsDate?.map((order) => order?.date)

  const data = {
    labels,
    datasets: [
      {
        label: "Total Sales",
        data: props?.analyticsData?.totalSalesVsDate?.map((order) => order?.sales),
        borderColor: "rgb(0, 0, 255)",
        backgroundColor: "rgba(0, 0, 255, 0.5)",
      },
    ],
  };

  return (
    <div className="Chart_wrapper">
      <p>
        {" "}
        Total sales -{" "}
        <span>
          {props?.currentCurrency}
          {props?.analyticsData?.totalSales?.toFixed(2)}
        </span>
      </p>
      <Line options={options} data={data} />
    </div>
  );
};

export default TotalOrdersChart;
