import React, { useCallback, useRef, useState } from "react";
import AdminHeader from "../admin/AdminHeader";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import Box from "@mui/material/Box";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { useNavigate } from "react-router-dom";
import TabPanelInner from "../admin/TabPanelInner";
import { useEffect } from "react";
import { getstorelist } from "../API";
import Pagination from "@mui/material/Pagination";
import axios from "axios";
import Loader from "../components/Popups/Loader";
import { toast } from "react-toastify";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function AdminDashboard(props) {
  let token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [orderaccepted, setorderaccepted] = useState(false);
  // const[cancelorder,setcancelorder] =useState(false)
  const [orderlist, setorderlist] = useState([]);
  const[groupsColor,setGroupsColor] = useState({});
  const [groupsAssignedToRobots, setGroupsAssignedToRobots] = useState({});
  const [refresh, setrefresh] = useState(false);
  const size = 10;
  const [page, setpage] = useState(1);
  const [showcount, setshowcount] = useState();
  const [Paginationlength, setPaginationlength] = useState(false);
  const [currentpage, setcurrentpage] = useState(1);
  const [storelistData, setStorelistData] = useState([]);
  const [selectedFleet, setSelectedFleet] = useState();
  const [loaderState, setloaderstate] = useState(false);
  const [robotData, setRobotData] = useState([]);
  const [ordersSuggestion,setOrdersSuggestion] = useState([]);
  const refreshfunction = () => {
    setrefresh(!refresh);
  };
  //  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [, setOpen] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [currentStore, setcurrentStore] = useState();

  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === "null" ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    getstorelist()
      .then((res) => {
        // console.log('response storelist',res)
        if (res.data.status === 200 || res.data.status === "200") {
          setStorelistData(res.data.message);
          setcurrentStore(res.data?.message[0]?.storeId);
          setSelectedFleet(res.data?.message[0]?.fleetId);
        } else {
          setStorelistData([]);
        }
      })
      .catch((err) => {
        console.log("Error in Store list", err);
      });
  }, []);

  useEffect(() => {
    setorderaccepted(true);
    setrefresh(!refresh);
  }, []);

  const tailwindColors = [
    '#FF0000', // Red
    '#FFA500', // Orange
    '#FFFF00', // Yellow
    '#FFFFFF', // White
    '#87CEEB', // Sky Blue
    '#FF69B4', // Hot Pink
    '#ADFF2F', // Green-Yellow
    '#008000', // Green
    '#00FF00', // Lime
    '#00CED1', // Dark Turquoise
    '#1E90FF', // Dodger Blue
    '#0000FF', // Blue
    '#8A2BE2', // Blue-Violet
    '#9400D3', // Dark Violet
    '#FF00FF', // Magenta
    '#FF4500', // Orange-Red
    '#8B008B', // Dark Magenta
    '#800080', // Purple
    '#A52A2A', // Brown
    '#8B4513', // Saddle Brown
    '#696969', // Dim Gray
    '#808080', // Gray
    '#A9A9A9', // Dark Gray
    '#000000', // Black
    '#DC143C', // Crimson
    '#FF6347', // Tomato
    '#FFD700', // Gold
    '#32CD32', // Lime Green
    '#20B2AA', // Light Sea Green
  ];
  
  useEffect(() => {
    const createdGroupColors = {};
    const robotGroups = {};
    if(ordersSuggestion?.length){
    ordersSuggestion.forEach((record, index) => {
      const { suggestedSchedule } = record || {};
  
      if (suggestedSchedule) {
        const { groupId, robotId } = suggestedSchedule;
  
        // Color Assignment
        if (!createdGroupColors[groupId]) {
          // Assign a new color only if it hasn't been assigned before
          const colorIndex = Object.keys(createdGroupColors).length % tailwindColors.length;
          createdGroupColors[groupId] = tailwindColors[colorIndex];
        }
  
        // Robot Groups
        if (!robotGroups[robotId]) {
          robotGroups[robotId] = new Set([groupId]);
        } else {
          robotGroups[robotId].add(groupId);
        }
      }
    });
  }
    // Convert Set to Array
  const groupsAssignedToRobots = {};
  Object.keys(robotGroups).forEach((robotId) => {
    groupsAssignedToRobots[robotId] = Array.from(robotGroups[robotId]);
  });
    setGroupsAssignedToRobots(groupsAssignedToRobots);
    setGroupsColor(createdGroupColors);
  }, [ordersSuggestion]);
  
  const fetchOrderSuggestionClass = (orderId) => {
    let color = ""
    if(ordersSuggestion?.length){
      const fetchGroupId = ordersSuggestion.filter(order => order.orderId === orderId)
      if(fetchGroupId?.length){
      color = groupsColor[fetchGroupId[0]?.suggestedSchedule?.groupId]
      }
    }
    return color
  }

  const updateState = useCallback(async () => {
    if(token) {

      return axios
        .post(
          `${process.env.REACT_APP_ORDERAT}/order/list`,
          {
            storeId: currentStore,
            page: page,
            pageSize: size,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // setTotalOrders(res.data.data.count)
          // setorderlist(res.data.data.order)
          // console.log('res',res)
          setorderlist(
            res.data.order?.filter((item) => item.status !== "booked")
          );
          // console.log('res.data.order',res)
          if (res.data?.count > size) {
            setPaginationlength(true);
            // setPaginationlength(Paginationlength => Paginationlength+1)
          }
          if (res.data.count % 1 === 0) {
            setshowcount(Math.floor(res.data.count / 10) + 1);
            // console.log('res.data.data.count%1',res.data.data.count%1)
            // console.log('showcount',showcount)
          } else {
            setshowcount(res.data.order?.length / 10);
            setPaginationlength(false);
          }
        })
        .catch((err) => {
          console.log("Error in Order list", err);
        });
    } else {
      return null
    }
  }, [currentpage, size, currentStore, token]);

  function parseItem(item) {
    const [, stringPart = "", numberPart = 0] =
      /(^[a-zA-Z]*)(\d*)$/.exec(item) || [];
    return [stringPart, numberPart];
  }

  const updateRobotETA = useCallback(async () => {
    if (selectedFleet && token) {
      // console.log("token inside ETA", token)
      return await axios
        .post(
          `${process.env.REACT_APP_APIOTTONOMY_URL}getRobotsETA/${selectedFleet}`
        )
        .then((res) => {
          setRobotData(
            res.data.result.sort((a, b) => {
              const [stringA, numberA] = parseItem(a.robotId);
              const [stringB, numberB] = parseItem(b.robotId);
              const comparison = stringA.localeCompare(stringB);
              return comparison === 0
                ? Number(numberA) - Number(numberB)
                : comparison;
            })
          );
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.message);
          // toast.error("Something went wrong")
        });
    } 
    // console.log("token outside ETA", token)
    return null;
    // console.log("Result",res.data.result.sort((a,b) => a.robotId - b.robotId))
  }, [selectedFleet, token]);
  
  const updateOrdersSuggestion = useCallback(async () => {
    let listOfOrderIds = orderlist?.filter(order => order.status === "order accepted")?.map(order => order.orderId)
    if (listOfOrderIds?.length) {
      return await axios
        .post(
          `${process.env.REACT_APP_APIOTTONOMY_URL}order/getOrdersSchedule`,
          {
            orderIds: listOfOrderIds,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setOrdersSuggestion(
            res?.data?.data
          );
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.message);
          // toast.error("Something went wrong")
        });
    }
    // console.log("Result",res.data.result.sort((a,b) => a.robotId - b.robotId))
  }, [orderlist]);

  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useIntervalAsync(updateState, 5000);
  useIntervalAsync(updateRobotETA, 3000);
  useIntervalAsync(updateOrdersSuggestion,3000)

  const handleChangetwo = (event, value) => {
    setpage(value);
    setcurrentpage(value);
    setValue(0)
  };

  const getStatusclass = (orderStatus) => {
    switch (orderStatus.toLowerCase()) {
      case "InProgress":
        return "dashboard-tab-inprogress";
      case "booked":
        return "";
      case "order delivered":
        return "dashboard-tab-delivered";
      case "order cancelled":
        return "dashboard-tab-cancel";
      case "order removed":
        return "dashboard-tab-cancel";
      case "customer did not pickup":
        return "dashboard-tab-cancel";
      case "New":
        return "dashboard-tab-new-new";
      case "order confirmed":
        return "dashboard-tab-new-new";
      default:
        return "dashboard-tab-inprogress";
    }
  };

  return (
    <>
      <div className="DesktopMain-wrapper">
        {loaderState ? (
          <>
            {" "}
            <Loader handleClose={handleClose} />
          </>
        ) : null}

        <AdminHeader order={true} storelistData={storelistData} />
  
        {/* <div className="flex flex-col w-full py-3 px-16 border-[1px] border-[#7e847e] rounded-md gap-y-2 flex-wrap">
         
            {robotData.map((robot, index) => (
              <div className="flex flex-col w-max p-3 border-[1px] border-[#E8E800] rounded-md gap-y-2" key={"robot_no_" + index}>
                {robot.robotId}
                <div className="flex flex-wrap gap-x-1">
                  <div
                    className="w-1/6 h-1.5 rounded"
                    style={{ backgroundColor: "rgb(255,0,0)" }}
                  ></div>
                  <div
                    className="w-1/6 h-1.5 rounded"
                    style={{ backgroundColor: "rgb(255,165,0)" }}
                  ></div>
                </div>
              </div>
            ))}
          
        </div> */}

        <div className="container padding-top">
        <div className="flex flex-col gap-2 py-2 text-white bg-white rounded-lg">

{robotData && robotData.length !== 0 ? (
  <div>
  <h1 className="flex items-center pb-2 text-lg font-bold text-ottonomyBlack">
  Robots
</h1>
  <div className="flex w-full overflow-x-auto gap-x-3">
    {robotData.map((robot, index) => (
             <div
        key={"robot__no__" + index}
        className="flex flex-col w-1/3 px-4 py-3 border rounded-lg shadow-white gap-y-2"
      >
      <div className="flex justify-between">
        <div className="flex flex-col leading-[19px]">
        <div className="flex font-semibold text-ottonomyBlack">{robot.robotId}
        </div>
          <span className="text-xs font-semibold text-ottonomyBlue">
            {robot.robotStatus === "UNAVAILABLE" && "Unavailable"}
            {
              robot.robotStatus === "AVAILABLE" &&
                robot.currentRobotAction &&
                robot.currentRobotAction.message === "MOVEUNLOAD" &&
                "Enroute to dropoff"
              // robot.currentRobotAction.locationName
            }
            
            {
              robot.robotStatus === "AVAILABLE" &&
                robot.currentRobotAction &&
                robot.currentRobotAction.message === "MOVELOAD" &&
                "Enroute to pickup"
              // robot.currentRobotAction.locationName
            }
                        {
              robot.robotStatus === "AVAILABLE" &&
                robot.currentRobotAction &&
                robot.currentRobotAction.message === "MOVEHOME" &&
                "Enroute to home"
              // robot.currentRobotAction.locationName
            }
            {robot.robotStatus === "AVAILABLE" &&
              !robot.currentRobotAction &&
              "Available at the pickup point"}
          </span>
        </div>
        {robot.robotStatus === "AVAILABLE" &&
        robot.ETA &&
        robot.ETA !== 0 ? (
          <div className="flex flex-col items-center">
            <span className="text-[11px] font-semibold text-black">
              Back in
            </span>
            <div className="flex items-end gap-1 text-black">
              <span className="text-xl font-semibold leading-[21px]">
                {Math.ceil(robot.ETA / 60)}
              </span>
              <span className="text-xs font-extralight">MINS</span>
            </div>
          </div>
        ) : null}
      </div>
        <div className="flex flex-wrap gap-x-1">
            {groupsAssignedToRobots[robot.robotId] && Object.values(groupsAssignedToRobots[robot.robotId]).map((group, index) => (
                <div style={{ backgroundColor: groupsColor[group] }} className="w-1/6 h-1.5 rounded" key={index} />
              ))}
         
      </div>
        </div>
    ))}
    </div>
   </div> 
) : (
  <div className="text-[rgb(255,255,255,0.7)] font-semibold flex items-center justify-center w-full h-full ">
    {" "}
    No Robot to show
  </div>
)}
</div>
          <div className="row">
            <div className="col-md-4" style={{ textAlign: "left" }}>
              <div className="AdminDashboard-tabs-left-side">
                <>
                  <div className="AdminDashboard-tabs-left-side_heading">
                    <h1>
                      {" "}
                      Stores <ChevronRightOutlinedIcon />
                      {storelistData?.length > 1 ? (
                        <span>
                          <Dropdown
                            onSelect={(eventKey) => {
                              const selectedItem = storelistData.find(
                                (item) => item.storeId === eventKey
                              );
                              setcurrentStore(selectedItem.storeId);
                              setSelectedFleet(selectedItem.fleetId);
                            }}
                            id="Store_dropdown_select"
                          >
                            <Dropdown.Toggle id="dropdown-basic">
                              {currentStore}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="custom-dropdown-menu">
                              {storelistData?.map((item) => (
                                <Dropdown.Item
                                  key={item.storeId}
                                  eventKey={item.storeId}
                                >
                                  {item.storeId}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </span>
                      ) : (
                        <span> {currentStore}</span>
                      )}
                    </h1>
                  </div>
                </>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ overflow: "unset" }}
                  indicatorColor={""}
                  className="Single-tab-wrapper-main"
                >
                  {/* {console.log('currentStore',currentStore,'orderlist',orderlist)} */}
                  {orderlist?.map((item, index) => {
                    if (item.storeId === currentStore) {
                      return (
                        <Tab
                          key={index}
                          label={
                            <div className="dashboard-order-list-wrapper">
                              <div className="dashboard-order-list-wrapper-inner-div-one">
                                <h5> {item.orderId}</h5>
                                <p className={getStatusclass(item.status)}>
                                  {" "}
                                  {item.status}
                                </p>
                              </div>
                              <div className="dashboard-order-list-wrapper-inner-div-two">
                                <p className="dashboard-order-list-wrapper-inner-div-two-p-one">
                                  {" "}
                                  {item.storeId}{" "}
                                </p>
                                <div style={{ backgroundColor: fetchOrderSuggestionClass(item.orderId) }} className="w-full h-1"/>
                                {/* <div className="Dashboard-page-seperator-line"></div> */}
                                <p className="dashboard-order-list-wrapper-inner-div-two-p-two">
                                  {" "}
                                  {item.drop.locationName}
                                </p>
                              </div>
                            </div>
                          }
                          {...a11yProps(index)}
                          className="Inner-tab"
                        />
                      );
                    }
                  })}
                </Tabs>
                {Paginationlength ? (
                  <Pagination
                    className="PaginationWrapper"
                    count={showcount}
                    page={page}
                    onChange={handleChangetwo}
                    sx={{ width: "100%" }}
                  />
                ) : null}
              </div>
            </div>
            <div className="col-md-8" style={{ textAlign: "left" }}>
              <div className="AdminDashboard-tabs-right-side">
                {orderlist?.map((item, index) => {
                  return (
                    <TabPanel
                      key={index}
                      value={value >= orderlist?.length ? 0 : value}
                      index={index}
                    >
                      <TabPanelInner
                        storelistData={storelistData}
                        setloaderstate={setloaderstate}
                        loaderState={loaderState}
                        data={item}
                        refreshfunction={refreshfunction}
                        setcancel={props.setcancel}
                        canel={props.canel}
                        handleClosetwo={props.handleClosetwo}
                      />
                    </TabPanel>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="Circular_progress">
            {" "}
            {orderlist?.length === 0 ||
            orderlist === "Order Not Found" ||
            orderlist === undefined ||
            orderlist === null ? (
              <>
                <h3> No orders to show</h3>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
