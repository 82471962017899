import axios from "axios";
let token = localStorage.getItem("token");
export const login = (values) => {
  return axios
    .post(`${process.env.REACT_APP_ORDERAT}/admin/login`, {
      email: values?.email,
      password: values?.password,
    })
    .then((res) => {
      return res;
    });
};

export const getstorelist = () => {
  let token = localStorage.getItem("token");
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/store/list`,
      {
        zone: process.env.REACT_APP_ZONE,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getproductlist = (storeId) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/product/list`,
      {
        storeId: storeId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const setstorestatus = (storeId, status, timing) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/store/setStatus`,
      {
        status: status,
        storeId: storeId,
        timing: timing,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const acceptOrder = (storeId, orderId) => {
  //    console.log('storeId',storeId,orderId)
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/order/accept`,
      {
        storeId: storeId,
        orderId: orderId,
        status: "accepted",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const updateOrderStatus = (
  storeId,
  orderId,
  orderStatus,
  statusCode
) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/order/updateOrderStatus`,
      {
        storeId: storeId,
        orderId: orderId,
        orderStatus: orderStatus,
        statusCode: statusCode,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const confirmOrderlist = () => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/order/list/confirm`,
      {
        storeId: "SUBWAY",
        page: 1,
        pageSize: 10,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const cancelOrder = (storeId, orderId, canceltestarea, cancelreason, cancelledItems) => {
  let cancellationReason={};
  if (cancelreason === "other") {
    cancellationReason.text = canceltestarea;
  } else {
    cancellationReason.text = cancelreason;
  }
  if(cancelledItems.length){
    cancellationReason.unavailableItems = cancelledItems
  }
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/order/delete`,
      {
        orderId: orderId,
        storeId: storeId,
        cancellationReason: cancellationReason,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const setproductstatus = (productId, status) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/product/update/status`,
      {
        productId: productId,
        status: status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getorderlist = (size, page, token) => {
  if(token) {
    return axios
      .post(
        `${process.env.REACT_APP_ORDERAT}/order/list`,
        {
          page: page,
          pageSize: size,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res;
      });
  } else {
    return null;
  }
};

export const placeorder = (data) => {
  // console.log('datadata',data)
  return axios
    .post(
      `${process.env.REACT_APP_APIOTTONOMY_URL}order/v1/placeOrder`,
      {
        orderId: data?.orderId,
        fleetId: "61f7886316bad77abc2f6bb2",
        customerName: "",
        customerMobileNumber: data?.mobileNumber,
        customerEmail: data?.userName,
        orderDetails: "",
        orderValue: "",
        pickupId: "SUBWAY",
        dropId: data?.dropId,
        qrcodePickup: data?.orderId,
        qrcodeDrop: data?.orderId,
        timeout: "180",
        timeoutBeh: "1000",
        deadline: "1000",
        pin: data?.orderId?.substr(-4),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      //    console.log(' Placeorder response', response)
      return response;
    })
    .catch((error) => {
      console.log("placeorder Error", error);
      return error;
    });
};

export const OpenCabinAPI = (data) => {
  return axios
    .post(
      `${process.env.REACT_APP_APIOTTONOMY_URL}interrupt/v1/openDoorByOrderId`,
      {
        orderId: data.orderId,
        fleetId: "61f7886316bad77abc2f6bb2",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const editstore = (storeId, storeData) => {
  return axios
    .put(
      `${process.env.REACT_APP_ORDERAT}/store/edit`,
      {
        storeId: storeId,
        storeData: storeData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const askuploadtype = (filetype, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_ORDERAT}/product/askuploadurl`,
      {
        type: filetype,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const updateimagetoCdn = (fileurl, filepath, token) => {
  return axios
    .post(fileurl, filepath, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": `multipart/form-data`,
      },
    })
    .then((res) => {
      return res;
    });
};

export const editProduct = (productId, productValues, storeId, token) => {
  // console.log("productValues",productValues)
  let body =
    productValues.productImage !== ""
      ? {
          en: {
            description: productValues.englishProductDescription,
            name: productValues.englishProductName,
            category: productValues.productCategoryEnglish
          },
          it: {
            description: productValues.spanishProductDescription,
            name: productValues.spanishProductName,
            category: productValues.productCategorySpanish
          },
          price: productValues.productPrice,
          category: productValues.productCategoryEnglish,
          image: productValues.productImage,
          storeId: storeId,
          ingredients: productValues.ingredients
        }
      : {
          en: {
            description: productValues.englishProductDescription,
            name: productValues.englishProductName,
            category: productValues.productCategoryEnglish
          },
          it: {
            description: productValues.spanishProductDescription,
            name: productValues.spanishProductName,
            category: productValues.productCategorySpanish
          },
          price: productValues.productPrice,
          category: productValues.productCategoryEnglish,
          storeId: storeId,
          ingredients: productValues.ingredients,
          image: "https://imagedelivery.net/HOqw_0sYZTUgFQ-soBOgtg/0d87b47a-ec28-4329-85d3-14fac7903000/thumb"
        };

  return axios
    .put(`${process.env.REACT_APP_ORDERAT}/product/edit/${productId}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};

export const addProduct = (productValues, storeId, token) => {
  let body =
    productValues.productImage !== ""
      ? {
          productId: productValues.productId,
          en: {
            description: productValues.englishProductDescription,
            name: productValues.englishProductName,
          },
          it: {
            description: productValues.spanishProductDescription,
            name: productValues.spanishProductName,
          },
          price: productValues.productPrice,
          category: productValues.productCategory,
          image: productValues.productImage,
          storeId: storeId,
        }
      : {
          productId: productValues.productId,
          en: {
            description: productValues.englishProductDescription,
            name: productValues.englishProductName,
          },
          it: {
            description: productValues.spanishProductDescription,
            name: productValues.spanishProductName,
          },
          price: productValues.productPrice,
          category: productValues.productCategory,
          storeId: storeId,
          stock: 10
        };

  return axios
    .post(`${process.env.REACT_APP_ORDERAT}/product/create`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};

export const allAllergens = () => {
  return axios
  .get(`${process.env.REACT_APP_ORDERAT}/product/allAllergens`, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  })
  .then((res) => {
    return res;
  });
}